import i18n from 'javascripts/i18n';
import moment from 'moment-timezone';

const DEFAULT_DATE_FORMATS = [
  'DD/MM/YYYY', 'DD-MM-YYYY', 'DD.MM.YYYY', 'DDMMYYYY',
  'YYYY/MM/DD', 'YYYY-MM-DD', 'YYYY.MM.DD', 'YYYYMMDD',
];
const DEFAULT_DATE_TIME_FORMATS = [
  'DD/MM/YYYY HH:mm', 'DD-MM-YYYY HH:mm', 'DD.MM.YYYY HH:mm', 'DDMMYYYY HH:mm',
  'YYYY/MM/DD HH:mm', 'YYYY-MM-DD HH:mm', 'YYYY.MM.DD HH:mm', 'YYYYMMDD HH:mm',
  'DD MMMM YYYY, HH:mm',
];
const INPUT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
const INPUT_DATE_FORMAT = 'DD/MM/YYYY';
const DATE_TIME_FORMAT_FOR_SAVE = 'YYYY/MM/DD HH:mm';
const DATE_FORMAT_FOR_SAVE = 'YYYY/MM/DD';

export function humanTimeInHours(time) {
  const hours = Math.floor(time / 1);
  const minutes = Math.round((time % 1) * 60);
  let timeTemplate;

  if (hours === 0) {
    timeTemplate = 'execute_in_minutes';
  } else if (minutes === 0) {
    timeTemplate = 'execute_in_hours';
  } else {
    timeTemplate = 'execute_in_hours_with_minutes';
  }

  return i18n.t(`human_time_formats.${timeTemplate}`, { h: hours, m: minutes });
}

export function NormalizeTime(time, type) {
  let normalizedTime = parseInt(time, 10);
  const max_value = type === 'hour' ? 23 : 59;

  if (normalizedTime < 0 || Number.isNaN(normalizedTime)) {
    normalizedTime = '00';
  } else if (normalizedTime > max_value) {
    normalizedTime = max_value;
  } else if (normalizedTime < 10) {
    normalizedTime = `0${normalizedTime}`;
  }

  return normalizedTime;
}

export function normalizeDateTimeForInput(dateTime, convertDefaultTimeTo = 'dayStart') {
  if (!dateTime) {
    if (convertDefaultTimeTo === 'dayStart') {
      return { date: null, hour: '00', minute: '00' };
    }
    return { date: null, hour: '23', minute: '59' };
  }

  const momentParsedDateTime = moment(dateTime, DEFAULT_DATE_TIME_FORMATS, i18n.langWithoutIndustry, true);
  const validDate = momentParsedDateTime.isValid() ? momentParsedDateTime.toString()
    : moment().tz(gon.timezone);
  const parsedDate = moment.parseZone(validDate);

  return {
    date: parsedDate.format(INPUT_DATE_FORMAT),
    hour: parsedDate.format('HH'),
    minute: parsedDate.format('mm'),
  };
}

export function normalizedDateForInput(date) {
  const momentParsedDate = moment(date, DEFAULT_DATE_FORMATS, true);
  const validDate = momentParsedDate.isValid() ? momentParsedDate.toString() : moment().tz(gon.timezone);
  return moment.parseZone(validDate).format(INPUT_DATE_FORMAT);
}

export function humanDateTime(dateTime) {
  return moment.parseZone(new Date(dateTime)).format(INPUT_DATE_TIME_FORMAT);
}

export function normalizedDateForSave(date) {
  const momentParsedDate = moment(date, DEFAULT_DATE_FORMATS, true);
  const parsedDate = moment.parseZone(momentParsedDate.toString());
  return parsedDate.isValid() ? parsedDate.format(DATE_FORMAT_FOR_SAVE) : moment().tz(gon.timezone);
}

export function normalizedDateTimeForSave(dateTime) {
  if (!dateTime.date) { return null; }

  const momentParsedDate = moment(dateTime.date, DEFAULT_DATE_FORMATS, true);
  const parsedDate = moment.parseZone(
    `${momentParsedDate.format(DATE_FORMAT_FOR_SAVE)} ${dateTime.hour}:${dateTime.minute}`,
    DATE_TIME_FORMAT_FOR_SAVE,
  );
  const validDate = parsedDate.isValid() ? parsedDate : moment().tz(gon.timezone);
  return validDate.format(DATE_TIME_FORMAT_FOR_SAVE);
}
